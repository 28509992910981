'use client';

import React from 'react';
import { usePathname } from 'next/navigation';

import Button from '@/components/Button';
import FeedbackForm from '@/components/FeedbackForm';
import Icon from '@/components/Icon';
import ScreenEnterLogger from '@/components/loggers/ScreenEnterLogger';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/Modal';

import styles from './styles.module.scss';

const AUTHORIZED_PATHS = ['/product/posters'];

function LicenseFeedbackButton() {
  const pathname = usePathname();

  const isVisible = AUTHORIZED_PATHS.includes(pathname);
  return isVisible ? (
    <Dialog>
      <DialogTrigger>
        <div className={styles.dialogButton}>
          <Icon name="feedback" aria-label="Give us feedback" />
        </div>
      </DialogTrigger>
      <DialogContent size="small">
        <ScreenEnterLogger
          name="EducationalPostersfeedback"
          additionnalData={{ origin: 'educational-posters-editor' }}
        />
        <div className={styles.formContainer}>
          <FeedbackForm origin="educational-posters-editor">
            <DialogClose>
              <Button theme="white" width="full" type="button">
                Close
              </Button>
            </DialogClose>
          </FeedbackForm>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default LicenseFeedbackButton;
