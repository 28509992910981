import styles from './styles.module.scss';

function LoaderCSSBasic() {
  return (
    <div className={styles.ldsRing}>
      <svg viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
          stroke="currentColor"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </div>
  );
}

export default LoaderCSSBasic;
