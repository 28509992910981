'use client';

import React, { PropsWithChildren, useState, useTransition } from 'react';

import Button from '@/components/Button';
import submit from '@/components/FeedbackForm/action';
import {
  FEEDBACK_FORM_EMAIL,
  FEEDBACK_FORM_FEEDBACK,
} from '@/components/FeedbackForm/constants';
import LoaderCSSBasic from '@/components/LoaderCSS/BasicLoader';

import styles from './styles.module.scss';

interface Props {
  origin: string;
}

function FeedbackForm({ origin, children }: PropsWithChildren<Props>) {
  const [isPending, startTransition] = useTransition();
  const [isSent, setIsSent] = useState(false);

  const buttonText = isSent ? 'Feedback sent!' : 'Send feedback';

  return !isSent ? (
    <>
      <h1>Let us know!</h1>

      <form
        className={styles.form}
        action={(formData) =>
          startTransition(async () => {
            await submit(formData, origin);
            setIsSent(true);
          })
        }
      >
        <label className={styles.label} htmlFor={FEEDBACK_FORM_FEEDBACK}>
          Our team reads every feedback, so don’t hesitate to go into details!
          <textarea
            className={styles.input}
            id={FEEDBACK_FORM_FEEDBACK}
            name={FEEDBACK_FORM_FEEDBACK}
            placeholder="Likes/dislikes, problems, suggestions?"
            rows={5}
            required
            disabled={isSent}
          />
        </label>
        <label className={styles.label} htmlFor="email">
          If you are willing to be contacted by our customer service success
          team, please provide your email address.
          <input
            className={styles.input}
            id={FEEDBACK_FORM_EMAIL}
            name={FEEDBACK_FORM_EMAIL}
            type="email"
            placeholder="Your email (optional)"
            disabled={isSent}
          />
        </label>
        <div className={styles.button}>
          <Button
            type="submit"
            disabled={isPending || isSent}
            theme="secondary"
          >
            {isPending ? (
              <div className={styles.loaderContainer}>
                <LoaderCSSBasic />
              </div>
            ) : (
              buttonText
            )}
          </Button>
        </div>
      </form>
    </>
  ) : (
    <div className={styles.sent}>
      <div>
        <h1 className={styles.sentTitle}>Your feedback has been sent!</h1>
        <p className={styles.description}>Thank you!</p>
        <p className={styles.description}>
          We’ll share it with our team and use it to improve our service.
        </p>
      </div>
      {children}
    </div>
  );
}

export default FeedbackForm;
